/*
 * This file is shared between the client and server.
 */
import { AllMimeTypes, Extensions, FileFolders } from 'common/constants/files';
import { SupportedLanguages } from 'common/i18n/supportedLanguages';
import { HubspotObjectTypes } from 'common/integrations/hubspot/HubspotConstants';
import NotificationActions from 'common/notifications/NotificationActions';
import PostStatusColors, {
  DefaultColors as DefaultPostStatusColors,
} from 'common/postStatus/PostStatusColors';
import PostStatusTypes from 'common/postStatus/PostStatusTypes';
import ImpactFactorTypes from 'common/prioritization/ImpactFactorTypes';
import SDKValidation from 'common/sdk/SDKValidation';
import { ThirdPartyCompanyDefaultField } from 'common/thirdPartyCompanies/constants';
import boardURLNameBlocklist from 'common/util/boardURLNameBlocklist';
import { RelativeDateRanges } from 'common/util/dateRanges';
import getExtension from 'common/util/getExtension';
import isNil from 'common/util/isNil';
import trimBlankCharacters from 'common/util/trimBlankCharacters';
import unallowedCompanyNames from 'common/util/unallowedCompanyNames';
import unallowedSubdomains from 'common/util/unallowedSubdomains';

function isString(value) {
  return typeof value === 'string';
}

const validateInput = {
  validate: function (format, data) {
    for (var key in format) {
      var entry = format[key];
      var validator;
      if (typeof entry === 'object') {
        if (entry.nullable) {
          if (data[key] === undefined || data[key] === null) {
            continue;
          }
        }
        if (entry.empty) {
          if (data[key] === '') {
            continue;
          }
        }
        if (typeof entry.default !== 'undefined') {
          if (typeof data[key] === 'undefined') {
            data[key] = entry.default;
          }
        }
        validator = entry.validator;
      } else if (typeof entry === 'function') {
        validator = entry;
      } else {
        return {
          details: 'malformed validation entry',
          message: entry.errorMessage || 'internal error',
        };
      }

      if (validator.isShape) {
        if (typeof data[key] === 'string') {
          try {
            data[key] = JSON.parse(data[key]);
          } catch (e) {
            return {
              details: `Invalid JSON passed for ${key}`,
              message: entry.errorMessage || 'internal error',
            };
          }
        }
      }

      if (!validator(data[key])) {
        return {
          details: `Value "${data[key]}" for key "${key}" is invalid`,
          message: entry.errorMessage || 'invalid input',
        };
      }

      if (typeof entry.postTransform === 'function') {
        data[key] = entry.postTransform(data[key]);
      }
    }
    return null;
  },

  commaSeparatedListOf: (validator) => (list) => {
    if (typeof list !== 'string') {
      return false;
    }

    const values = list.split(',');
    const areAllValid = values.every(validator);
    return areAllValid;
  },

  arrayOf: (validator, minLength, maxLength) => (value) => {
    if (!Array.isArray(value)) {
      return false;
    }

    const array = value;

    // check if the array is within the specified lengths
    if (typeof maxLength === 'number' && array.length > maxLength) {
      return false;
    } else if (typeof minLength === 'number' && array.length < minLength) {
      return false;
    }

    for (var i = 0; i < array.length; i++) {
      const item = array[i];
      if (!validator(item)) {
        return false;
      }
    }

    return true;
  },

  shape: (shape) => {
    if (shape === null || typeof shape !== 'object') {
      console.error('invalid shape passed to validateInput.shape');
      return false;
    }

    const validator = (object) => {
      if (object === null || typeof object !== 'object') {
        console.error('non-object passed for validateInput.shape');
        return false;
      }

      const error = validateInput.validate(shape, object);
      if (error) {
        console.error(error.message);
      }
      return !error;
    };
    validator.isShape = true;

    return validator;
  },

  oneOf: (array) => (value) => {
    for (var i = 0; i < array.length; i++) {
      const item = array[i];
      if (item === value) {
        return true;
      }
    }
    return false;
  },

  oneOfType: (validators) => (value) => {
    for (var i = 0; i < validators.length; i++) {
      const validator = validators[i];
      if (validator(value)) {
        return true;
      }
    }
    return false;
  },

  board: {
    name: (value) => {
      if (!isString(value)) {
        return false;
      }
      if (value.length <= 0 || value.length > 40) {
        return false;
      }
      return true;
    },
    strings: {
      createCTA: (value) => {
        if (!isString(value)) {
          return false;
        }
        return value.length >= 1 && value.length <= 15;
      },
      createHeading: (value) => {
        if (!isString(value)) {
          return false;
        }
        return value.length >= 1 && value.length <= 25;
      },
      description: (value) => {
        if (!isString(value)) {
          return false;
        }
        return value.length >= 0 && value.length <= 200;
      },
      detailsField: (value) => {
        if (!isString(value)) {
          return false;
        }
        return value.length >= 1 && value.length <= 20;
      },
      detailsPlaceholder: (value) => {
        if (!isString(value)) {
          return false;
        }
        return value.length >= 0 && value.length <= 60;
      },
      formCTA: (value) => {
        if (!isString(value)) {
          return false;
        }
        return value.length >= 1 && value.length <= 60;
      },
      titleField: (value) => {
        if (!isString(value)) {
          return false;
        }
        return value.length >= 1 && value.length <= 20;
      },
      titlePlaceholder: (value) => {
        if (!isString(value)) {
          return false;
        }
        return value.length >= 0 && value.length <= 60;
      },
    },
    urlName: (value) => {
      if (boardURLNameBlocklist.hasOwnProperty(value)) {
        return false;
      }
      return validateInput.urlName(value);
    },
  },

  captcha: (value) => {
    return validateInput.shape({
      version: validateInput.oneOf(['v2', 'v3']),
      value: validateInput.string,
    });
  },

  category: {
    name: (value) => {
      if (typeof value !== 'string') {
        return false;
      }

      value = value.trim();
      if (value.length < 1 || value.length > 30) {
        return false;
      }

      return true;
    },
  },

  company: function (company) {
    if (!company) {
      return false;
    }

    if (typeof company !== 'object') {
      return false;
    }

    if (!company._id || !validateInput.id(company._id)) {
      return false;
    }

    return true;
  },

  companyPostStatus: {
    color: (value) => {
      if (typeof value !== 'string') {
        return false;
      }

      // Expects: Complete, InProgress, ...
      if (Object.keys(DefaultPostStatusColors).includes(value)) {
        return true;
      }

      // Expects: Red.200
      const [color, code] = value.split('.');
      if (PostStatusColors[color]?.[code]) {
        return true;
      }

      return false;
    },

    name: (value) => {
      return typeof value === 'string' && value.trim().length > 0 && value.trim().length < 30;
    },

    type: (value) => {
      return Object.values(PostStatusTypes).includes(value);
    },
  },

  hubspot: {
    objectType: (value) => {
      return Object.values(HubspotObjectTypes).includes(value);
    },
  },

  integrations: {
    slack: {
      channelOptions: (value) => {
        if (typeof value !== 'object') {
          return false;
        }

        const options = ['allowContributorSpamReports', 'disableSpamButton'];
        const allBoolean = options.every((type) => validateInput.boolean(value[type]));

        return allBoolean;
      },
    },

    notificationTypes: (value) => {
      if (typeof value !== 'object') {
        return false;
      }
      const notificationTypes = [
        'newPosts',
        'newComments',
        'newEntries',
        'newVotes',
        'statusChanges',
        'voteMilestones',
      ];
      const allBoolean = notificationTypes.every((type) => validateInput.boolean(value[type]));

      return allBoolean;
    },
  },

  language: (value) => {
    return Object.values(SupportedLanguages).includes(value);
  },

  post: {
    eta: (eta) => {
      if (!eta.match(/^[0-9]{2}\/[0-9]{4}$/)) {
        return false;
      }

      const month = Number(eta.substring(0, 2));
      const year = Number(eta.substring(3, 7));

      if (month < 1 || month > 12 || year < 2019 || year > 2100) {
        return false;
      }

      return true;
    },
  },

  posts: {
    sort: (value) => {
      return [
        'mrr',
        'newest',
        'oldest',
        'relevance',
        'score',
        'statusChanged',
        'trending',
      ].includes(value);
    },
    status: (statusList) => {
      if (typeof statusList !== 'string') {
        return false;
      }

      const statuses = statusList.split(',');
      if (statuses.length === 0) {
        return false;
      }

      for (var i = 0; i < statuses.length; i++) {
        const status = statuses[i];
        if (
          status !== 'open' &&
          status !== 'under review' &&
          status !== 'on hold' &&
          status !== 'planned' &&
          status !== 'in progress' &&
          status !== 'complete' &&
          status !== 'closed'
        ) {
          return false;
        }
      }

      return true;
    },
  },

  primitives: {
    anything: () => true,
    boolean: (value) => {
      return typeof value === 'boolean';
    },
    booleanString: (value) => {
      return value === 'true' || value === 'false';
    },
    date: (value) => {
      return value instanceof Date && !Number.isNaN(+value);
    },
    integer: (value) => {
      if (typeof value !== 'number' && typeof value !== 'string') {
        return false;
      }
      var n = Number(value);
      return Number.isInteger(n) && n >= 0;
    },
    isoDateString: (value) => {
      if (typeof value !== 'string') {
        return false;
      }
      return value.match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/);
    },
    isoString: (value) => {
      const date = Date.parse(value);
      return !Number.isNaN(+date);
    },
    number: (value) => {
      return typeof value === 'number';
    },
    object: (value) => {
      return !!(value && typeof value === 'object' && !Array.isArray(value));
    },
    string: (value) => {
      return typeof value === 'string';
    },
    url: (value) => {
      if (typeof value !== 'string') {
        return false;
      }

      //Regex comes from: https://mathiasbynens.be/demo/url-regex
      const UrlRegex = /^(https?):\/\/(-\.)?([^\s/?\.#]+\.?)+(\/[^\s]*)?$/;
      return !!value.match(UrlRegex);
    },
  },

  reportFrequency: (value) => {
    return value === 'daily' || value === 'weekly' || value === 'never';
  },

  roadmapFactor: {
    name: (value) => {
      return typeof value === 'string' && value.trim().length > 0 && value.trim().length < 30;
    },
    type: (value) => {
      return Object.values(ImpactFactorTypes)
        .map((factorType) => factorType.name)
        .includes(value);
    },
    weight: (value) => {
      // must be 0-100, increments of 0.1
      if (typeof value !== 'number') {
        return false;
      }
      if (value < 0 || value > 100) {
        return false;
      }
      return validateInput.primitives.integer(value * 10);
    },
  },

  roadmapPost: {
    effort: (value) => {
      // must be 0.1-100, increments of 0.1
      if (typeof value !== 'number') {
        return false;
      }
      if (value < 0.1 || value > 233) {
        return false;
      }
      return validateInput.primitives.integer(value * 10);
    },
  },

  status: {
    type: (value) => {
      const statusTypes = ['active', 'complete', 'closed'];
      return statusTypes.includes(value);
    },
  },

  googleAnalytics: {
    propertyID: (value) => {
      if (typeof value !== 'string') {
        return false;
      }

      const GRegexp = /^G-[0-9A-Z]+$/; // used for GA4

      return value.match(GRegexp);
    },
  },

  reports: {
    numericFilter: (filter) => {
      if (!filter || typeof filter !== 'object') {
        return false;
      }

      const { operator, value } = filter;
      const validOperators = ['is', 'isNot', 'greaterThan', 'lessThan', 'isNull', 'isNotNull'];
      const isValidOperator = validOperators.includes(operator);
      const castedValue = Number(value);
      const isValidValue = isNil(value) || !Number.isNaN(castedValue);
      return isValidOperator && isValidValue;
    },
    textFilter: (filter) => {
      if (!filter || typeof filter !== 'object') {
        return false;
      }

      const { operator, value } = filter;
      const validOperators = ['is', 'isNot', 'contains', 'doesNotContain', 'isNull', 'isNotNull'];
      const isValidOperator = validOperators.includes(operator);
      const isValidValue = isNil(value) || validateInput.primitives.string(value);
      return isValidOperator && isValidValue;
    },
  },

  roadmap: {
    effortType: (value) => {
      return (
        value === 'fibonacci' ||
        value === 'numberToOneHundred' ||
        value === 'numberToTen' ||
        value === 'stars'
      );
    },
    name: (value) => {
      if (typeof value !== 'string') {
        return false;
      }

      const trimmedValue = value.trim();
      if (trimmedValue.length === 0 || trimmedValue.length > 40) {
        return false;
      }

      return true;
    },
    columns: (value) => {
      if (!Array.isArray(value)) {
        return false;
      }

      const uniqueColumnIDs = new Set(value);
      if (uniqueColumnIDs.size !== value.length) {
        // there are repeated column ids
        return false;
      }

      return true;
    },
    columnWidths: (value) => {
      if (typeof value !== 'object') {
        return false;
      }
      const columnIDs = Object.keys(value);
      const uniqueColumnWidthIDs = new Set(columnIDs);
      if (uniqueColumnWidthIDs.size !== columnIDs.length) {
        return false;
      }

      return true;
    },
  },

  tags: {
    name: (value) => {
      if (typeof value !== 'string') {
        return false;
      }

      value = value.trim();
      if (value.length < 1 || value.length > 30) {
        return false;
      }

      return true;
    },
  },

  changelog: {
    details: function (details) {
      if (typeof details !== 'string') {
        return false;
      }
      return true;
    },
    label: {
      name: (value) => {
        if (typeof value !== 'string') {
          return false;
        }

        value = value.trim();
        if (value.length < 1 || value.length > 30) {
          return false;
        }

        return true;
      },
    },
    sort: (value) => {
      return ['created', 'lastSaved', 'nonPublishedFirst', 'publishedAt'].includes(value);
    },
    status: function (status) {
      return status === 'draft' || status === 'scheduled' || status === 'published';
    },
    title: function (title) {
      if (typeof title !== 'string') {
        return false;
      } else if (title.length <= 0) {
        return false;
      }
      return true;
    },
    type: function (type) {
      return type === 'new' || type === 'improved' || type === 'fixed';
    },
  },

  customFields: function (value) {
    if (typeof value !== 'object') {
      return false;
    } else if (Array.isArray(value)) {
      return false;
    }

    const fieldNames = Object.keys(value);
    const areFieldNamesValid = fieldNames.every((fieldName) => {
      return fieldName.length > 0 && fieldName.length < 30;
    });
    const areFieldValuesValid = fieldNames.every((fieldName) => {
      const fieldValue = value[fieldName];
      if (fieldValue === undefined || fieldValue === null) {
        return true;
      } else if (typeof fieldValue === 'boolean' || typeof fieldValue === 'number') {
        return true;
      } else if (typeof fieldValue === 'string') {
        return fieldValue.length < 200;
      } else if (validateInput.primitives.date(fieldValue)) {
        return true;
      }

      return false;
    });
    return areFieldNamesValid && areFieldValuesValid;
  },

  customPostField: {
    label: (value) => {
      if (!isString(value)) {
        return false;
      }

      return value.length >= 1 && value.length <= 30;
    },

    name: (value) => {
      if (!isString(value)) {
        return false;
      }

      return value.length >= 1 && value.length <= 20;
    },

    option: (value) => {
      if (!isString(value)) {
        return false;
      }

      return value.length >= 1 && value.length <= 30;
    },

    placeholder: (value) => {
      if (!isString(value)) {
        return false;
      }

      return value.length >= 1 && value.length <= 35;
    },

    valueMap: function (value) {
      // an object mapping customPostFieldIDs to field values
      if (value === null || typeof value !== 'object') {
        return false;
      }

      const ValidTypes = [validateInput.string, validateInput.arrayOf(validateInput.string)];

      for (const [id, type] of Object.entries(value)) {
        const isKeyValid = validateInput.id(id);
        const isValueValid = validateInput.oneOfType(ValidTypes)(type);
        if (!isKeyValid || !isValueValid) {
          return false;
        }
      }
      return true;
    },
  },

  null: function (value) {
    if (value !== undefined && value !== null) {
      return false;
    }

    return true;
  },

  boolean: function (value) {
    return typeof value === 'boolean';
  },

  companyName: function (name) {
    if (typeof name !== 'string') {
      return false;
    }

    // Reject if it's like a url
    if (new RegExp(/(http:|https:|\\|\/)/g).test(name)) {
      return false;
    }

    const trimmedName = name.trim().toLowerCase();
    if (trimmedName.length === 0 || trimmedName.length > 100) {
      return false;
    }

    if (unallowedCompanyNames[trimmedName]) {
      return false;
    }

    if (trimmedName.match(/tramadol/i)) {
      return false;
    }

    return true;
  },

  string: function (value) {
    if (typeof value !== 'string') {
      return false;
    }

    return true;
  },

  integer: function (value) {
    if (typeof value !== 'string') {
      return false;
    }

    var n = ~~Number(value);
    return String(n) === value && n >= 0;
  },

  integerBetween: (min, max) => (value) => {
    const isInteger = validateInput.primitives.integer(value);
    if (!isInteger) {
      return false;
    }

    const num = Number(value);
    return num >= min && num <= max;
  },

  id: function (id) {
    if (!id) {
      return false;
    }

    if (typeof id === 'object' && !!id.toString) {
      id = id.toString();
    }

    if (typeof id !== 'string') {
      return false;
    }

    if (id.length !== 24) {
      return false;
    }

    var regex = /^[a-fA-F0-9]{24}$/;
    return !!id.match(regex);
  },

  hexColor: function (color) {
    if (typeof color !== 'string') {
      return false;
    }

    var regex = /^#(?:[0-9a-fA-F]{3}){1,2}$/;
    return !!color.match(regex);
  },

  sessionID: function (sessionID) {
    if (typeof sessionID !== 'string') {
      return false;
    }

    if (sessionID.length !== 36) {
      return false;
    }

    var regex = /^[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$/;
    return !!sessionID.match(regex);
  },

  viewer: function (viewer) {
    if (!viewer) {
      return false;
    }

    if (typeof viewer !== 'object') {
      return false;
    }

    if (!viewer._id || !validateInput.id(viewer._id)) {
      return false;
    }

    return true;
  },

  viewerIsMember: function (viewerIsMember) {
    if (typeof viewerIsMember !== 'boolean') {
      return false;
    }
    return true;
  },

  object: function (object) {
    return typeof object === 'object';
  },

  pages: function (pages) {
    if (typeof pages !== 'number') {
      return false;
    }

    // isInteger
    if (pages % 1 !== 0) {
      return false;
    }

    if (pages < 1 || pages > 50) {
      return false;
    }

    return true;
  },

  postSort: function (sort) {
    return [
      'created',
      'mrr',
      'newest',
      'oldest',
      'opportunity',
      'relevance',
      'score',
      'statusChanged',
      'trendingScore',
    ].includes(sort);
  },

  postStatus: function (status) {
    if (typeof status !== 'string') {
      return false;
    }

    if (
      status === 'open' ||
      status === 'under review' ||
      status === 'on hold' ||
      status === 'planned' ||
      status === 'in progress' ||
      status === 'closed' ||
      status === 'complete'
    ) {
      return true;
    }

    return false;
  },

  postTitle: function (title) {
    if (typeof title !== 'string') {
      return false;
    }

    const length = trimBlankCharacters(title).length;
    if (length <= 0 || length > 400) {
      return false;
    }

    return true;
  },

  postDetails: function (details) {
    if (typeof details !== 'string') {
      return false;
    }

    const length = trimBlankCharacters(details).length;
    if (length < 0 || length > 5000) {
      return false;
    }

    return true;
  },

  postImageURLs: function (imageURLsJSON) {
    if (typeof imageURLsJSON !== 'string') {
      return false;
    }

    var imageURLs;
    try {
      imageURLs = JSON.parse(imageURLsJSON);
    } catch (e) {
      imageURLs = null;
    }

    if (!imageURLs) {
      return false;
    }

    if (typeof imageURLs !== 'object' || imageURLs.length === undefined) {
      return false;
    }

    if (imageURLs.length < 0 || imageURLs.length > 10) {
      return false;
    }

    for (var i = 0; i < imageURLs.length; i++) {
      var imageURL = imageURLs[i];
      if (!validateInput.imageURL(imageURL)) {
        return false;
      }
    }

    return true;
  },

  relativeDateRange: function (value) {
    return Object.values(RelativeDateRanges).includes(value);
  },

  scim: {
    identityProvider: function (value) {
      return ['onelogin'].includes(value);
    },
  },

  thirdPartyCompanies: {
    customFieldMapping: function (value) {
      if (!value || typeof value !== 'object') {
        return false;
      }

      const defaultFields = Object.values(ThirdPartyCompanyDefaultField);
      const isValid = defaultFields.every(
        (defaultField) =>
          isNil(value[defaultField]) || validateInput.primitives.string(value[defaultField])
      );

      return isValid;
    },
  },

  microsoftTeams: {
    webhookURL: function (value) {
      if (typeof value !== 'string') {
        return false;
      }

      try {
        const url = new URL(value);
        const isWorkflowURL =
          url.hostname.endsWith('logic.azure.com') && url.pathname.startsWith('/workflows/');
        return isWorkflowURL;
      } catch (e) {
        return false;
      }
    },

    name: function (value) {
      if (typeof value !== 'string') {
        return false;
      }

      if (value.length < 1 || value.length > 100) {
        return false;
      }

      return true;
    },
  },

  commentValue: function (value) {
    if (typeof value !== 'string') {
      return false;
    }

    const length = trimBlankCharacters(value).length;
    if (length <= 0 || length > 2500) {
      return false;
    }

    return true;
  },

  file: function (file) {
    if (typeof file !== 'object') {
      return false;
    }

    if (file.size > 4000000) {
      return false;
    }

    if (!file.size || typeof file.size !== 'number') {
      return false;
    }

    // 5 mb
    if (file.size > 5000000) {
      return false;
    }

    return true;
  },

  fileUpload: function (file) {
    if (typeof file !== 'object') {
      return false;
    }

    if (
      !file.buffer ||
      typeof file.buffer !== 'object' ||
      !file.contentLength ||
      typeof file.contentLength !== 'number'
    ) {
      return false;
    }

    // 5 mb
    if (file.contentLength > 5000000) {
      return false;
    }

    return true;
  },

  imageFile: function (image) {
    if (typeof image !== 'object') {
      return false;
    }

    if (
      !image.size ||
      typeof image.size !== 'number' ||
      !image.type ||
      typeof image.type !== 'string'
    ) {
      return false;
    }

    const validImageTypes = [
      'image/gif',
      'image/jpeg',
      'image/png',
      'image/webp',
      'image/svg+xml',
      'image/svg',
    ];

    if (!validImageTypes.includes(image.type)) {
      return false;
    }

    // 8 mb
    if (image.size > 8000000) {
      return false;
    }

    return true;
  },

  imageType: function (imageType) {
    if (typeof imageType !== 'string') {
      return false;
    }

    const validImageTypes = [
      'image/gif',
      'image/jpeg',
      'image/png',
      'image/webp',
      'image/svg+xml',
      'image/svg',
    ];

    if (!validImageTypes.includes(imageType)) {
      return false;
    }

    return true;
  },

  imageUpload: function (image) {
    if (typeof image !== 'object') {
      return false;
    }

    if (
      !image.buffer ||
      typeof image.buffer !== 'object' ||
      !image.contentLength ||
      typeof image.contentLength !== 'number'
    ) {
      return false;
    }

    // 8 mb
    if (image.contentLength > 8000000) {
      return false;
    }

    return true;
  },

  imageURL: function (imageURL) {
    if (typeof imageURL !== 'string') {
      return false;
    }

    if (
      imageURL.indexOf('https://canny-assets.io/images/') !== 0 &&
      imageURL.indexOf('https://canny.io/images/') !== 0 &&
      imageURL.indexOf('https://productpains.com/images/') !== 0
    ) {
      return false;
    }

    var extension = getExtension(imageURL);
    if (!extension) {
      return false;
    }

    const validExtensions = ['jpg', 'jpeg', 'gif', 'png', 'svg', 'webp'];
    if (!validExtensions.includes(extension)) {
      return false;
    }

    return true;
  },

  monthlySpend: SDKValidation.company.monthlySpend,

  publicFile: function (file) {
    if (typeof file !== 'object') {
      return false;
    }

    if (
      !file.size ||
      typeof file.size !== 'number' ||
      !file.type ||
      typeof file.type !== 'string' ||
      !file.name ||
      typeof file.name !== 'string'
    ) {
      return false;
    }

    if (!AllMimeTypes.includes(file.type)) {
      return false;
    }

    // 8 mb
    if (file.size > 8000000) {
      return false;
    }

    return true;
  },

  publicFileType: function (publicFileType) {
    if (typeof publicFileType !== 'string') {
      return false;
    }

    return AllMimeTypes.includes(publicFileType);
  },

  publicFileUpload: function (file) {
    if (typeof file !== 'object') {
      return false;
    }

    if (
      !file.buffer ||
      typeof file.buffer !== 'object' ||
      !file.contentLength ||
      typeof file.contentLength !== 'number' ||
      !file.filename ||
      typeof file.filename !== 'string'
    ) {
      return false;
    }

    // 8 mb
    if (file.contentLength > 8000000) {
      return false;
    }

    return true;
  },

  publicNonImageFileURL: function (fileURL) {
    if (typeof fileURL !== 'string') {
      return false;
    }

    const urlPaths = [
      `https://canny.io/${FileFolders.files}/`,
      `https://canny-assets.io/${FileFolders.files}/`,
    ];

    const isValidURL = urlPaths.some((urlPath) => fileURL.includes(urlPath));

    if (!isValidURL) {
      return false;
    }

    var extension = getExtension(fileURL);
    if (!extension) {
      return false;
    }

    if (!Extensions[extension]) {
      return false;
    }

    return true;
  },

  publicNonImageFileURLs: function (fileURLsJSON) {
    if (typeof fileURLsJSON !== 'string') {
      return false;
    }

    let fileURLs;
    try {
      fileURLs = JSON.parse(fileURLsJSON);
    } catch (e) {
      fileURLs = null;
    }

    if (!fileURLs) {
      return false;
    }

    if (typeof fileURLs !== 'object' || fileURLs.length === undefined) {
      return false;
    }

    if (fileURLs.length < 0 || fileURLs.length > 10) {
      return false;
    }

    for (let i = 0; i < fileURLs.length; i++) {
      const fileURL = fileURLs[i];
      if (!validateInput.publicNonImageFileURL(fileURL)) {
        return false;
      }
    }

    return true;
  },

  // like Andrew Rasmussen, not arasmussen
  userName: function (userName) {
    if (typeof userName !== 'string') {
      return false;
    }

    // Reject if it's like a url
    if (new RegExp(/[:\/]/g).test(userName)) {
      return false;
    }

    const trimmedName = userName.trim();
    if (trimmedName.length < 1 || trimmedName.length > 50) {
      return false;
    }

    return true;
  },

  password: function (password) {
    if (typeof password !== 'string') {
      return false;
    }

    if (password.length < 6 || password.length > 200) {
      return false;
    }

    return true;
  },

  email: function (email) {
    if (typeof email !== 'string') {
      return false;
    }

    if (email.length < 5 || email.length > 200) {
      return false;
    }

    var regex =
      /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.([a-z]+)|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;
    return !!email.match(regex);
  },

  urlName: function (urlName) {
    if (typeof urlName !== 'string') {
      return false;
    }

    if (urlName.length < 1 || urlName.length > 85) {
      return false;
    }

    var unallowedCharactersRegex = /^[a-z0-9-]+$/;
    var startsWithDashRegex = /^-/;
    var endsWithDashRegex = /-$/;
    if (
      !urlName.match(unallowedCharactersRegex) ||
      urlName.match(startsWithDashRegex) ||
      urlName.match(endsWithDashRegex)
    ) {
      return false;
    }

    return true;
  },

  subdomain: function (subdomain) {
    if (!validateInput.urlName(subdomain)) {
      return false;
    }

    if (unallowedSubdomains[subdomain]) {
      return false;
    }

    return true;
  },

  voteScore: function (score) {
    if (typeof score !== 'number') {
      return false;
    }

    return score === 0 || score === 1;
  },

  notificationAction: (notificationAction) => {
    return Object.keys(NotificationActions).includes(notificationAction);
  },

  emojiString: (string) => {
    const regex = /\p{Emoji}/u;
    return regex.test(string);
  },
};

export default validateInput;
