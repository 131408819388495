import React, { type ReactNode, useContext, useEffect, useState } from 'react';

import { ArrowLeft, ArrowRight } from 'lucide-react';

import SentrySDK from 'common/3rd/SentrySDK';
import { reloadAllBoards } from 'common/actions/boards';
import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import { BoardAccess } from 'common/constants/boards';
import { BoardsContext } from 'common/containers/BoardsContainer';
import { CompanyContext } from 'common/containers/CompanyContainer';
import cloneElementWithProps from 'common/core/cloneElementWithProps';
import connect from 'common/core/connect';
import LazyLoadedImage from 'common/LazyLoadedImage';
import CompanyRoleNames from 'common/roles/CompanyRoleNames';
import AdminBoardPreview from 'common/subdomain/admin/AdminBoardPreview';
import Button from 'common/ui/ButtonV2';
import { P } from 'common/ui/Text';
import getPlanIDFromUseCase from 'common/util/getPlanIDFromUseCase';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import validateInput from 'common/validateInput';

import { type BoardInput } from './AdminOnboardingCreateBoard';
import AdminOnboardingReference from './AdminOnboardingReference';
import { type UseCase } from './Types';

import Canny from 'img/canny-wordmark.svg';

import type { Company } from 'common/api/endpoints/companies';
import type { Dispatch } from 'redux-connect';

import 'css/components/subdomain/admin/_AdminOnboarding.scss';

enum Step {
  What = 'what',
  How = 'how',
  Where = 'where',
  Source = 'source',
  CreateBoard = 'create-board',
  InviteTeam = 'invite-team',
  Redirect = 'redirect',
}

type Location = {
  pathname: string;
};

type Props = {
  children: ReactNode;
  location: Location;
  reloadBoards: () => void;
  reloadCompany: () => void;
  router: {
    push: (path: string) => void;
    replace: (path: string) => void;
  };
};

const AdminOnboardingWrapper = ({
  children,
  location,
  reloadBoards,
  reloadCompany,
  router,
}: Props) => {
  // context
  let boards = useContext(BoardsContext);
  const company = useContext<Company>(CompanyContext);

  // Boards could potentially be loading. If so, just chill out for a bit
  if (!Array.isArray(boards)) {
    boards = [];
  }

  const hasBoards = boards.length > 0;

  // state
  const [board, setBoard] = useState<BoardInput>({
    name: 'Feature Requests',
    url: 'feature-requests',
  });
  const [error, setError] = useState<string | null>(null);
  const [inviteEmails, setInviteEmails] = useState<string[]>(Array(3).fill(''));
  const [loading, setLoading] = useState<boolean>(false);
  const [skipping, setSkipping] = useState<boolean>(false);
  const [useCase, setUseCase] = useState<UseCase>({
    customAccess: false,
    feedbackSources: [],
    indexed: false,
    marketingSources: [],
    motivations: [],
    otherFeedbackSource: null,
    otherMarketingSource: null,
    otherMotivation: null,
    private: true,
    users: [],
  });

  // effects
  useEffect(() => {
    if (!company.viewerIsMember) {
      router.replace('/');
      return;
    }
  }, [company, router]);

  // helpers
  const canContinue = (currentStep: Step) => {
    const feedbackHows = ['autopilotFeedback', 'behalfFeedback', 'directFeedback'];

    switch (currentStep) {
      case Step.What:
        return useCase.motivations?.length > 0;
      case Step.How:
        return !!useCase.motivations?.find((m) => feedbackHows.includes(m));
      case Step.Where:
        return useCase.feedbackSources.length > 0;
      case Step.Source:
        return true;
      case Step.CreateBoard:
        return hasBoards || (board.name && board.url);
      case Step.InviteTeam:
        return true;
      default:
        return false;
    }
  };

  const completeFlow = async () => {
    const response = await AJAX.post('/api/company/updateUseCase', {
      ...useCase,
      completed: true,
    });
    const { error: requestError } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (requestError) {
      onRequestError(requestError);
      setError('Something went wrong, please contact our team for support.');
      return;
    }

    await reloadCompany();
    router.push('/admin');
  };

  const getContinueText = (currentStep: Step) => {
    switch (currentStep) {
      case Step.InviteTeam:
        return 'Send Invites';
      default:
        return 'Continue';
    }
  };

  const getCurrentStep = () => {
    const { pathname } = location;
    const path = pathname.split('/');
    if (path.length !== 4 || path[2] !== 'onboarding') {
      return null;
    }

    return path[3] as Step;
  };

  const onBack = async () => {
    setError(null);

    const currentStep = getCurrentStep();
    if (!currentStep) {
      onUndefinedStep();
      return;
    }

    const backStep = whereIsBack(currentStep);
    const path = `/admin/onboarding/${backStep}`;
    router.push(path);
  };

  const onContinue = async () => {
    if (loading) {
      return;
    }

    setError(null);
    setLoading(true);

    const { trialingPlan } = company;

    const currentStep = getCurrentStep();
    if (!currentStep) {
      onUndefinedStep();
      return;
    }

    const { customAccess, private: isPrivate } = useCase;
    const onCreateBoardStep = currentStep === Step.CreateBoard;
    const requiredPlanID = getPlanIDFromUseCase(useCase);
    const shouldStartTrial = onCreateBoardStep && !trialingPlan && requiredPlanID;

    if (shouldStartTrial) {
      const requestError = await startTrial(requiredPlanID);
      if (requestError) {
        onRequestError(requestError);
      }
    }

    const shouldCreateBoard = onCreateBoardStep && !hasBoards;
    if (shouldCreateBoard) {
      let access = BoardAccess.public;
      if (customAccess) {
        access = BoardAccess.custom;
      } else if (isPrivate) {
        access = BoardAccess.private;
      }

      const response = await AJAX.post('/api/boards/create', {
        access,
        indexed: useCase.indexed,
        name: board.name,
        unlisted: false,
        urlName: board.url,
      });
      const { error: requestError } = parseAPIResponse(response, {
        isSuccessful: isDefaultSuccessResponse,
      });

      if (requestError) {
        onRequestError(requestError);
      }

      await reloadBoards();
    }

    const shouldSendInvites = currentStep === Step.InviteTeam;
    if (shouldSendInvites) {
      const sentInvites = await sendInvites();
      if (sentInvites) {
        await completeFlow();
      }

      setLoading(false);
      return;
    }

    const response = await AJAX.post('/api/company/updateUseCase', useCase);
    const { error: requestError } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (requestError) {
      onRequestError(requestError);
    }

    setLoading(false);

    const nextStep = whereIsNext(currentStep);
    if (!nextStep) {
      return;
    }

    const path = `/admin/onboarding/${nextStep}`;
    router.push(path);
  };

  const onSkip = async () => {
    setError(null);

    const currentStep = getCurrentStep();
    if (!currentStep) {
      onUndefinedStep();
      return;
    }

    const nextStep = whereIsNext(currentStep);
    if (nextStep) {
      const path = `/admin/onboarding/${nextStep}`;
      router.push(path);
      return;
    }

    if (skipping) {
      return;
    }

    setSkipping(true);
    await completeFlow();
    setSkipping(false);
  };

  const onRequestError = async (requestError: { type: string; message: string }) => {
    SentrySDK.captureException(
      new Error(`Unhandled Signup Flow Error! ${requestError.type}, ${requestError.message}`)
    );
  };

  const onUndefinedStep = async () => {
    const { pathname } = location;
    SentrySDK.captureException(new Error(`Reached Undefined Flow Step, ${pathname}`));
  };

  const sendInvites = async (): Promise<boolean> => {
    const notEmpty = inviteEmails.filter((email) => email !== '');
    if (notEmpty.length === 0) {
      setError('Please provide an email to invite');
      return false;
    }

    const validEmails = inviteEmails.filter((email) => validateInput.email(email));
    if (notEmpty.length !== validEmails.length) {
      setError('Please ensure all emails are valid');
      return false;
    }

    const ownerRole = company.roles.find((role) => role.name === CompanyRoleNames.owner);
    const response = await AJAX.post('/api/company/inviteAdmins', {
      emails: notEmpty,
      roleID: ownerRole?._id,
    });
    const { error: requestError } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (requestError) {
      onRequestError(requestError);
    }

    return true;
  };

  const startTrial = async (trialPlanID: string) => {
    await AJAX.post('/api/analytics/log', {
      key: 'growth_upsell_success',
      data: {
        feature: 'onboarding',
      },
    });
    const response = await AJAX.post('/api/billing/startPlanTrial', {
      planID: trialPlanID,
    });
    const { error: requestError } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });
    return requestError;
  };

  const whereIsBack = (currentStep: Step) => {
    const wantsFeedback = useCase.motivations?.includes('feedback');

    switch (currentStep) {
      case Step.How:
        return Step.What;
      case Step.Where:
        return Step.How;
      case Step.Source:
        return wantsFeedback ? Step.Where : Step.What;
      case Step.CreateBoard:
        return Step.Source;
      case Step.InviteTeam:
        return Step.CreateBoard;
      default:
        return;
    }
  };

  const whereIsNext = (currentStep: Step) => {
    const wantsFeedback = useCase.motivations?.includes('feedback');

    switch (currentStep) {
      case Step.What:
        return wantsFeedback ? Step.How : Step.Source;
      case Step.How:
        return Step.Where;
      case Step.Where:
        return Step.Source;
      case Step.Source:
        return Step.CreateBoard;
      case Step.CreateBoard:
        return Step.InviteTeam;
      default:
        return;
    }
  };

  const whoIsReference = (currentStep: Step) => {
    switch (currentStep) {
      case Step.What:
        return 'liya';
      case Step.Where:
        return 'chelsea';
      case Step.How:
        return 'ida';
      case Step.Source:
        return 'saravana';
      case Step.InviteTeam:
        return 'rachel';
      default:
        return;
    }
  };

  const renderSidebar = (currentStep: Step) => {
    if (currentStep === Step.CreateBoard) {
      return (
        <div className="boardPreviewWrapper">
          <AdminBoardPreview board={board} />
        </div>
      );
    }

    const reference = whoIsReference(currentStep);
    if (reference) {
      return <AdminOnboardingReference referenceKey={reference} />;
    }

    return;
  };

  if (!company.viewerIsMember) {
    return <></>;
  }

  const currentStep = getCurrentStep();
  if (!currentStep) {
    return (
      <div className="onboardingWrapper">
        <div className="onboarding">
          <div className="content">
            <LazyLoadedImage alt="Canny logo" className="cannyLogo" src={Canny} />
            <P variant="bodyLg" className="error">
              Something went wrong, please contact our team for support.
            </P>
          </div>
        </div>
        <div className="sidebar" />
      </div>
    );
  }

  if (currentStep === 'redirect') {
    return <>{children}</>;
  }

  const backStep = whereIsBack(currentStep);
  const nextStep = whereIsNext(currentStep);
  const canSkip = currentStep === Step.Source || currentStep === Step.InviteTeam;
  const continueText = getContinueText(currentStep);

  return (
    <div className="onboardingWrapper">
      <div className="onboarding">
        <div className="content">
          <LazyLoadedImage alt="Canny logo" className="cannyLogo" src={Canny} />
          <div className="question">
            {cloneElementWithProps(children, {
              board,
              inviteEmails,
              onChange: setUseCase,
              onChangeBoard: setBoard,
              onChangeInviteEmails: setInviteEmails,
              onSubmit: onContinue,
              useCase,
            })}
          </div>
          <div className="buttons">
            {backStep ? (
              <Button size="large" startIcon={ArrowLeft} onClick={onBack} variant="outlined">
                Back
              </Button>
            ) : (
              <div />
            )}
            <div className="rightButtons">
              {canSkip && (
                <Button size="large" loading={skipping} onClick={onSkip} variant="plain">
                  Skip step
                </Button>
              )}
              <Button
                size="large"
                disabled={!canContinue(currentStep)}
                endIcon={nextStep && ArrowRight}
                loading={loading}
                onClick={onContinue}>
                {continueText}
              </Button>
            </div>
          </div>
          <P variant="bodyLg" className="error">
            {error}
          </P>
        </div>
      </div>
      <div className="sidebar">{renderSidebar(currentStep)}</div>
    </div>
  );
};

export default connect(null, (dispatch: Dispatch) => ({
  reloadBoards: () => {
    return dispatch(reloadAllBoards());
  },
  reloadCompany: () => {
    return dispatch(reloadCompany());
  },
}))(AdminOnboardingWrapper);
