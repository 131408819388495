import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import connect from 'common/core/connect';
import ConfirmModal from 'common/modals/ConfirmModal';
import Spinner from 'common/Spinner';
import Tappable from 'common/Tappable';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminPlanErrors.scss';

const CustomStatusesDisableAlert = () => (
  <div className="customStatusesDisableAlert">
    <p>Are you sure you want to disable custom statuses for your&nbsp;team?</p>
    <ul>
      <li>Existing post statuses won't be affected</li>
      <li>The public roadmap will revert to the default statuses</li>
      <li>You won't be able to create, edit, or delete statuses</li>
      <li>You won't be able to select custom statuses when changing a post status</li>
    </ul>
  </div>
);

const limitPropType = PropTypes.shape({
  count: PropTypes.number,
  limit: PropTypes.number,
});

class AdminPlanErrors extends Component {
  constructor(props) {
    super(props);
  }

  static propTypes = {
    lostFeatures: PropTypes.arrayOf(PropTypes.string),
    planErrors: PropTypes.shape({
      features: PropTypes.arrayOf(PropTypes.string),
      integrations: PropTypes.arrayOf(PropTypes.string),
      limits: PropTypes.shape({
        admins: limitPropType,
        boards: limitPropType,
        integrations: limitPropType,
        prioritizationRoadmaps: limitPropType,
      }),
    }),
    reloadErrors: PropTypes.func,
    isReloading: PropTypes.func,
  };

  state = {
    error: null,
    reloadingElement: null,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isReloading && !this.props.isReloading) {
      this.setState({ reloadingElement: null });
    }
  }

  onDowngrade = (message, onConfirm, options) => {
    const { openModal } = this.props;

    openModal(ConfirmModal, {
      message,
      onConfirm,
      ...options,
    });
  };

  onRemoveAdminRoles = async () => {
    const response = await AJAX.post('/api/companyMembers/convertMembersToOwners');

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: {
        default:
          'Something went wrong changing your member roles, please contact our team for support.',
      },
    });

    if (error) {
      this.setState({ error: error.message });
      return;
    }

    this.props.reloadErrors();
    this.setState({ reloadingElement: 'features.adminRoles' });
  };

  onDisableAutoResponse = async () => {
    const response = await AJAX.post('/api/boards/disableBoardSettings', {
      boardSetting: 'autoResponse',
    });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: {
        default:
          'Something went wrong disabling smart replies, please contact our team for support.',
      },
    });

    if (error) {
      this.setState({ error: error.message });
      return;
    }

    this.props.reloadErrors();
    this.setState({ reloadingElement: 'features.autoResponse' });
  };

  onDisableCustomStatuses = async () => {
    const response = await AJAX.post('/api/postStatuses/restore');

    if (response === 'success') {
      this.props.reloadErrors();
      this.setState({ reloadingElement: 'features.customStatuses' });
      return;
    }

    this.setState({
      error: 'Something went wrong disabling custom statuses, please contact our team for support.',
    });
  };

  onEnableUserSubmissions = async () => {
    const response = await AJAX.post('/api/boards/disableBoardSettings', {
      boardSetting: 'showCreateForm',
    });

    if (response === 'success') {
      this.props.reloadErrors();
      this.setState({ reloadingElement: 'features.disableUserSubmissions' });
      return;
    }

    this.setState({
      error:
        'Something went wrong enabling users submissions, please contact our team for support.',
    });
  };

  onRemoveCustomPostFieldsFromBoards = async () => {
    const response = await AJAX.post('/api/boards/removeAllFields');

    if (response === 'success') {
      this.props.reloadErrors();
      this.setState({ reloadingElement: 'features.customPostFields' });
      return;
    }

    this.setState({
      error:
        'Something went wrong removing custom post fields, please contact our team for support.',
    });
  };

  onSwitchCustomAccessBoardsToPrivate = async () => {
    const response = await AJAX.post('/api/boards/disableBoardSettings', {
      boardSetting: 'customAccess',
    });

    if (response === 'success') {
      this.props.reloadErrors();
      this.setState({ reloadingElement: 'features.customAccess' });
      return;
    }

    this.setState({
      error:
        'Something went wrong switching your custom access boards to private, please contact our team for support.',
    });
  };

  onSwitchPrivateAuthorsToPublic = async () => {
    const response = await AJAX.post('/api/boards/disableBoardSettings', {
      boardSetting: 'privateAuthors',
    });

    if (response === 'success') {
      this.props.reloadErrors();
      this.setState({ reloadingElement: 'features.privateAuthors' });
      return;
    }

    this.setState({
      error:
        'Something went wrong switching your boards with private authors to public authors, please contact our team for support.',
    });
  };

  onSwitchPrivateBoardsToPublic = async () => {
    const response = await AJAX.post('/api/boards/disableBoardSettings', {
      boardSetting: 'privateBoards',
    });

    if (response === 'success') {
      this.props.reloadErrors();
      this.setState({ reloadingElement: 'features.privateBoards' });
      return;
    }

    this.setState({
      error:
        'Something went wrong switching your private boards to public, please contact our team for support.',
    });
  };

  onSwitchPrivateCommentBoardsToPublic = async () => {
    const response = await AJAX.post('/api/boards/disableBoardSettings', {
      boardSetting: 'privateComments',
    });

    if (response === 'success') {
      this.props.reloadErrors();
      this.setState({ reloadingElement: 'features.privateComments' });
      return;
    }

    this.setState({
      error:
        'Something went wrong switching your boards with private comments to public comments, please contact our team for support.',
    });
  };

  onSwitchPrivateChangelogToPublic = async () => {
    const { allowIdentified, domains } = this.props.company.changelog;
    const response = await AJAX.post('/api/changelog/editSettings', {
      allowIdentified,
      domains,
      enableEmailSubscriptions: false,
      private: false,
    });

    if (response === 'success') {
      this.props.reloadErrors();
      this.setState({ reloadingElement: 'features.privateChangelog' });
      return;
    }

    this.setState({
      error:
        'Something went wrong switching your private changelog to public, please contact our team for support.',
    });
  };

  onSwitchPrivateVotesToPublic = async () => {
    const response = await AJAX.post('/api/boards/disableBoardSettings', {
      boardSetting: 'privateVotes',
    });

    if (response === 'success') {
      this.props.reloadErrors();
      this.setState({ reloadingElement: 'features.privateVotes' });
      return;
    }

    this.setState({
      error:
        'Something went wrong switching your boards with private votes to public votes, please contact our team for support.',
    });
  };

  onUninstallReviewIntegrations = async () => {
    const { company } = this.props;
    const { installedSyncReviewIntegrations } = company;
    const sourceNotInstalledError = 'source not installed';

    const results = await Promise.all(
      installedSyncReviewIntegrations.map(async ({ platform }) =>
        AJAX.post(`/api/reviewSources/uninstall`, {
          platform,
        })
      )
    );

    const hasErrors = results.some((response) => {
      const { error } = parseAPIResponse(response, {
        isSuccessful: isDefaultSuccessResponse,
        errors: {
          sourceNotInstalledError,
          default: 'Something went wrong uninstalling review integrations.',
        },
      });
      return error ? error.message !== sourceNotInstalledError : false;
    });

    if (!hasErrors) {
      this.props.reloadErrors();
      this.setState({ reloadingElement: 'features.reviewSources' });
      return;
    }

    this.setState({
      error:
        'Something went wrong uninstalling review integrations, please contact our team for support.',
    });
  };

  getFeatureErrors = () => {
    const { planErrors } = this.props;
    if (!planErrors?.features) {
      return [];
    }

    const errorActions = {
      adminRoles: {
        cta: 'Make all admins owners',
        key: 'features.adminRoles',
        name: 'Admin Roles',
        todo: 'All admins must be made Owners',
        onTap: () =>
          this.onDowngrade(
            'Are you sure you want to make all admins owners? Updating all admins to owners may result in increased billing if you exceed your billable admin seat count.',
            this.onRemoveAdminRoles
          ),
      },
      autoResponse: {
        cta: 'Disable smart replies',
        key: 'features.autoResponse',
        name: 'Smart Replies',
        todo: 'Smart Replies must be disabled',
        onTap: () =>
          this.onDowngrade(
            'Are you sure you want to disable smart replies?',
            this.onDisableAutoResponse
          ),
      },
      customAccess: {
        cta: 'Switch to private',
        key: 'features.customAccess',
        name: 'Custom Access',
        onTap: () =>
          this.onDowngrade(
            'Are you sure you want to make all of your boards private?',
            this.onSwitchCustomAccessBoardsToPrivate
          ),
        todo: 'Boards with custom access must be switched to private',
      },
      customDomain: {
        cta: 'Remove domains',
        key: 'features.customDomain',
        link: '/admin/settings/custom-domain',
        name: 'Custom Domain',
        todo: 'Custom domains must be removed',
      },
      customEmailDomain: {
        cta: 'Remove email',
        key: 'features.customEmailDomain',
        link: '/admin/settings/user-emails/domain',
        name: 'Email whitelabeling',
        todo: 'Custom email address must be removed',
      },
      customPostFields: {
        cta: 'Remove custom post fields from boards',
        key: 'features.customPostFields',
        name: 'Post Fields',
        onTap: () =>
          this.onDowngrade(
            'Are you sure you want to remove custom post fields from boards?',
            this.onRemoveCustomPostFieldsFromBoards
          ),
        todo: 'Custom post fields must be removed',
      },
      customRoles: {
        cta: 'Delete custom roles',
        key: 'features.customRoles',
        name: 'Custom Roles',
        link: '/admin/settings/team/roles',
        todo: 'Custom roles must be deleted',
      },
      customStatuses: {
        cta: 'Disable custom statuses',
        key: 'features.customStatuses',
        name: 'Custom Statuses',
        onTap: () =>
          this.onDowngrade(<CustomStatusesDisableAlert />, this.onDisableCustomStatuses, {
            submitButtonValue: 'Yes, disable',
          }),
        todo: 'Custom statuses must be disabled',
      },
      disableUserSubmissions: {
        cta: 'Enable user submissions',
        key: 'features.disableUserSubmissions',
        name: 'Disabled User Submissions',
        onTap: () =>
          this.onDowngrade(
            'Are you sure you want to enable user submissions?',
            this.onEnableUserSubmissions
          ),
        todo: 'User submissions must be enabled',
      },
      privateAuthors: {
        cta: 'Switch to public',
        key: 'features.privateAuthors',
        name: 'Anonymized Boards',
        onTap: () =>
          this.onDowngrade(
            'Are you sure? Turning off anonymized boards will make all of your customers public to one another.',
            this.onSwitchPrivateAuthorsToPublic
          ),
        todo: 'Anonymized boards must be made public',
      },
      privateBoards: {
        cta: 'Switch to public',
        key: 'features.privateBoards',
        name: 'Private Boards',
        onTap: () =>
          this.onDowngrade(
            'Are you sure you want to make all of your boards public?',
            this.onSwitchPrivateBoardsToPublic
          ),
        todo: 'Private boards need to be switched to public boards',
      },
      privateChangelog: {
        cta: 'Switch to public',
        key: 'features.privateChangelog',
        name: 'Private Changelog',
        onTap: () =>
          this.onDowngrade(
            'Are you sure you want to make your changelog public?',
            this.onSwitchPrivateChangelogToPublic
          ),
        todo: 'Private changelog must be made public',
      },
      privateComments: {
        cta: 'Switch to public',
        key: 'features.privateComments',
        name: 'Private Comments',
        onTap: () =>
          this.onDowngrade(
            "Are you sure? Turning off private comments will make all of your customers' comments public to one another.",
            this.onSwitchPrivateCommentBoardsToPublic
          ),
        todo: 'Private comments must be made public',
      },
      privateVotes: {
        cta: 'Switch to public',
        key: 'features.privateVotes',
        name: 'Private Votes',
        onTap: () =>
          this.onDowngrade(
            "Are you sure? Turning off private votes will make all of your customers' votes public to one another.",
            this.onSwitchPrivateVotesToPublic
          ),
        todo: 'Private votes must be made public',
      },
      reviewSources: {
        cta: 'Uninstall review integrations',
        key: 'features.reviewSources',
        name: 'Review Integrations',
        onTap: () =>
          this.onDowngrade(
            'Are you sure? Uninstalling review integrations will remove all review integrations from your account.',
            this.onUninstallReviewIntegrations
          ),
        todo: 'Review integrations must be uninstalled',
      },
    };

    const { features } = planErrors;
    return features
      .map((feature) => {
        const action = errorActions[feature];
        return action
          ? {
              ...action,
              loading: action.key === this.state.reloadingElement,
            }
          : undefined;
      })
      .filter(Boolean);
  };

  getIntegrationErrors = () => {
    const { planErrors } = this.props;
    if (!planErrors?.integrations) {
      return [];
    }

    const integrations = {
      asana: { name: 'Asana', path: 'asana' },
      azure: { name: 'Azure', path: 'azure' },
      clickup: { name: 'Clickup', path: 'clickup' },
      discord: { name: 'Discord', path: 'discord' },
      gitHub: { name: 'GitHub', path: 'github' },
      gong: { name: 'Gong', path: 'gong' },
      gSuite: { name: 'GSuite', path: 'gsuite' },
      helpscout: { name: 'Help Scout', path: 'helpscout' },
      hubspot: { name: 'HubSpot', path: 'hubspot' },
      intercom: { name: 'Intercom', path: 'intercom' },
      jira: { name: 'Jira', path: 'jira' },
      linear: { name: 'Linear', path: 'linear' },
      microsoftTeams: { name: 'Microsoft Teams', path: 'microsoft-teams' },
      oidc: { name: 'OpenID Connect', path: 'oidc' },
      okta: { name: 'Okta', path: 'okta' },
      oneLogin: { name: 'One Login', path: 'onelogin' },
      salesforce: { name: 'Salesforce', path: 'salesforce' },
      segment: { name: 'Segment', path: 'segment' },
      slack: { name: 'Slack', path: 'slack' },
      zapier: { name: 'Zapier', path: 'zapier' },
      zendesk: { name: 'Zendesk', path: 'zendesk' },
    };

    const { integrations: integrationKeys } = planErrors;
    return integrationKeys
      .map((integrationKey) => {
        const integration = integrations[integrationKey];
        if (!integration) {
          return;
        }

        return {
          ...integration,
          key: integrationKey,
        };
      })
      .filter(Boolean)
      .map((integration) => {
        const { key, name, path } = integration;
        return {
          cta: `Uninstall ${name}`,
          key: `integrations.${key}`,
          link: `/admin/settings/${path}`,
          name,
          todo: `This plan does not support the ${name} integration.`,
          loading: `integrations.${key}` === this.state.reloadingElement,
        };
      });
  };

  getLimitErrors = () => {
    const { planErrors } = this.props;
    if (!planErrors?.limits) {
      return [];
    }

    const pluralize = (item, count) => item + (count > 1 ? 's need' : ' needs');
    const simplePluralize = (item, count) => `${item}${count > 1 ? 's' : ''}`;
    const errorActions = {
      activePosts: {
        cta: 'Complete posts',
        key: 'limits.activePosts',
        link: '/admin/feedback',
        name: 'Active post limit',
        todo: (count) => `At least ${count} ${pluralize('post', count)} to be completed.`,
      },
      admins: {
        cta: 'Manage team',
        key: 'limits.admins',
        link: '/admin/settings/team',
        name: 'Admin limit',
        todo: (count) => `At least ${count} ${pluralize('admin', count)} to be removed.`,
      },
      boards: {
        cta: 'Manage boards',
        key: 'limits.boards',
        link: '/admin/settings/boards',
        name: 'Board limit',
        todo: (count) => `At least ${count} ${pluralize('board', count)} to be removed.`,
      },
      boardFields: {
        cta: 'Manage boards',
        key: 'limits.boardFields',
        link: '/admin/settings/boards',
        name: 'Board custom field limit',
        todo: () =>
          `Confirm that all boards have fewer than ${
            planErrors.limits.boardFields.limit
          } "create post form" custom ${simplePluralize(
            'field',
            planErrors.limits.boardFields.limit
          )}.`,
      },
      integrations: {
        cta: 'Manage integrations',
        key: 'limits.integrations',
        link: '/admin/settings/integrations',
        name: 'Integration limit',
        todo: (count) => `At least ${count} ${pluralize('integration', count)} to be uninstalled.`,
      },

      prioritizationRoadmaps: {
        cta: 'Manage roadmaps',
        key: 'limits.prioritizationRoadmaps',
        link: '/admin/settings/roadmap/archive',
        name: 'Roadmap limit',
        todo: (count) =>
          `At least ${count} ${pluralize('roadmap', count)} to be deleted or archived.`,
      },
    };

    const { limits } = planErrors;
    const limitKeys = Object.keys(limits);
    return limitKeys
      .map((limitKey) => {
        const errorAction = errorActions[limitKey];
        if (!errorAction) {
          return;
        }

        const { count, limit } = limits[limitKey];
        return {
          ...errorAction,
          key: limitKey,
          todo: errorAction.todo(count - limit),
          loading: limitKey === this.state.reloadingElement,
        };
      })
      .filter(Boolean);
  };

  renderAction = (action) => {
    const { cta, link, onTap } = action;

    if (onTap) {
      return (
        <Tappable onTap={onTap}>
          <div className="cta">{cta}</div>
        </Tappable>
      );
    }

    if (link) {
      return (
        <a className="cta" href={link} rel="noopener" target="_blank">
          {cta}
        </a>
      );
    }
  };

  renderAllErrors = (errors) => {
    return errors.length > 0 ? <div className="errorActions">{errors}</div> : null;
  };

  renderErrorAction = (action) => {
    const { key, name, todo, loading } = action;

    return (
      <div className="action" key={key}>
        <div className="description">
          <div className="name">{name}</div>
          <div className="todo">{todo}</div>
        </div>
        {loading ? <Spinner /> : this.renderAction(action)}
      </div>
    );
  };

  renderLostFeatures = (hasOtherErrors) => {
    const featureLabels = {
      adminRoles: 'Admin Roles',
      advancedAnalytics: 'Analytics',
      autoResponse: 'Smart Replies',
      categories: 'Categories',
      changelogEmailSubscriptions: 'Changelog Email Subscriptions',
      customDomain: 'Custom Domain',
      customEmailDomain: 'Custom Email Domains',
      customStatuses: 'Custom Statuses',
      disableUserSubmissions: 'Disabling Posts from Users',
      internalComments: 'Internal Comments',
      liveSupport: 'Priority Support',
      moderationTools: 'Moderation Tools',
      postAutomation: 'Post Automation',
      postETAs: 'Post ETAs',
      postOwners: 'Post Owners',
      prioritizationRoadmap: 'Roadmaps',
      privateBoards: 'Private Boards',
      privateChangelog: 'Private Changelog',
      privateComments: 'Private Comments',
      privateVotes: 'Private Votes',
      removeBranding: 'Whitelabeling',
      shareRoadmaps: 'Share Roadmaps',
      summarizeComments: 'Summarize Comments',
      tags: 'Tags',
      unlistedBoards: 'Unlisted Boards',
      userProfiles: 'User Profiles',
      userSegmentation: 'User Segmentation',
      voteWeights: 'Vote weights',
    };

    const { lostFeatures } = this.props;
    if (!lostFeatures) {
      return null;
    }

    const lostFeatureLabels = lostFeatures.map((feature) => featureLabels[feature]).filter(Boolean);
    const makeList = new Intl.ListFormat('en', { style: 'long', type: 'conjunction' });
    return this.renderErrorAction({
      key: 'lostFeatures',
      name: hasOtherErrors
        ? 'Additionally, you will automatically lose access to:'
        : 'You will automatically lose access to:',
      todo: makeList.format(lostFeatureLabels),
    });
  };

  render = () => {
    const { error } = this.state;
    const featureErrors = this.getFeatureErrors();
    const integrationErrors = this.getIntegrationErrors();
    const limitErrors = this.getLimitErrors();
    const allErrors = [...featureErrors, ...integrationErrors, ...limitErrors]
      .filter(Boolean)
      .map(this.renderErrorAction);

    return (
      <div className="adminPlanErrors">
        {this.renderAllErrors(allErrors)}
        <div className="lostFeatures">{this.renderLostFeatures(allErrors.length > 0)}</div>
        {error && <div className="error">{error}</div>}
      </div>
    );
  };
}

export default compose(
  connect(null, (dispatch) => ({
    reloadCompany: () => {
      return dispatch(reloadCompany());
    },
  })),
  withContexts(
    {
      company: CompanyContext,
      openModal: OpenModalContext,
    },
    {
      forwardRef: true,
    }
  )
)(AdminPlanErrors);
