import React, { Component } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadBoard } from 'common/actions/boards';
import AJAX from 'common/AJAX';
import Pill, { DefaultPillStyles } from 'common/common/Pill';
import Toggle from 'common/common/Toggle';
import { BoardAccess } from 'common/constants/boards';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import { ViewerContext } from 'common/containers/ViewerContainer';
import ControlledDropdown from 'common/ControlledDropdown';
import connect from 'common/core/connect';
import Dropdown from 'common/Dropdown';
import FormField from 'common/FormField';
import AutoResizeTextarea from 'common/inputs/AutoResizeTextarea';
import TextInput from 'common/inputs/TextInput';
import Link from 'common/Link';
import ConfirmModal from 'common/modals/ConfirmModal';
import ErrorModal from 'common/modals/ErrorModal';
import AdminFeatureUpsell from 'common/subdomain/admin/AdminFeatureUpsell';
import Tappable from 'common/Tappable';
import SingleSelect from 'common/ui/SingleSelect';
import { H5, P } from 'common/ui/Text';
import isFree, { FreePlanID } from 'common/util/isFree';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminBoardPrivacyForm.scss';

const segmentDefaultValue = 'everyone';

class AdminBoardPrivacyForm extends Component {
  static propTypes = {
    allowIdentified: PropTypes.bool,
    board: PropTypes.shape({
      invites: PropTypes.array,
    }),
    company: PropTypes.shape({
      azure: PropTypes.object,
      plan: PropTypes.object,
      subdomain: PropTypes.string,
    }),
    domain: PropTypes.string,
    indexed: PropTypes.bool,
    openModal: PropTypes.func,
    segment: PropTypes.string,
    unlisted: PropTypes.bool,
    verifiedBy: PropTypes.string,
    viewer: PropTypes.object,
  };

  onChange = (update) => {
    const formFields = [
      'access',
      'allowIdentified',
      'domain',
      'indexed',
      'segment',
      'unlisted',
      'verifiedBy',
    ];
    const form = Object.keys(this.props)
      .filter((key) => formFields.includes(key))
      .reduce(
        (prev, key) => ({
          ...prev,
          [key]: this.props[key],
        }),
        {}
      );

    this.props.onChange({
      ...form,
      ...update,
    });
  };

  onDomainInputChange = (e) => {
    const { value } = e.target;
    const filteredValue = value.replace(/@/g, '');
    const domain = filteredValue !== '' ? filteredValue : null;
    this.onChange({ domain });
  };

  onEmailInputChange = (e) => {
    this.onChange({ email: e.target.value });
  };

  onRevokeAccess = (email) => {
    this.props.openModal(ConfirmModal, {
      message: "Are you sure you'd like to revoke " + email + "'s invite?",
      onConfirm: this.onRevokeAccessConfirmed.bind(this, email),
    });
  };

  onRevokeAccessConfirmed = (email) => {
    this.setState({
      error: null,
    });

    const { board } = this.props;
    AJAX.post(
      '/api/boards/revokeAccess',
      {
        boardID: board._id,
        email,
      },
      (response) => {
        if (response === 'success') {
          this.props.reloadBoard(board.urlName);
          return;
        }

        this.setState({
          error: 'Unable to revoke access, please try again later.',
        });
      }
    );
  };

  onSegmentChange = (segment) => {
    this.onChange({ segment: segment === segmentDefaultValue ? null : segment });
  };

  onVerifiedByChange = (verifiedBy) => {
    if (verifiedBy === 'azure') {
      const {
        company: { azure },
        openModal,
      } = this.props;
      const isAzureInstalled = azure && azure.organizationID;
      if (!isAzureInstalled) {
        openModal(ErrorModal, {
          message: (
            <div>
              Please install our <Link to="/admin/settings/azure">Azure AD integration</Link> before
              configuring private boards in this way.
            </div>
          ),
        });
        return;
      }
    } else if (verifiedBy === 'okta') {
      const {
        company: { okta },
        openModal,
      } = this.props;
      const isOktaInstalled = okta && okta.clientID;
      if (!isOktaInstalled) {
        openModal(ErrorModal, {
          message: (
            <div>
              Please install our <Link to="/admin/settings/okta">Okta integration</Link> before
              configuring private boards in this way.
            </div>
          ),
        });
        return;
      }
    } else if (verifiedBy === 'oidc') {
      const {
        company: { oidc },
        openModal,
      } = this.props;
      const isOIDCInstalled = oidc && oidc.clientID;
      if (!isOIDCInstalled) {
        openModal(ErrorModal, {
          message: (
            <div>
              Please install our <Link to="/admin/settings/oidc">OpenID Connect integration</Link>{' '}
              before configuring private boards in this way.
            </div>
          ),
        });
        return;
      }
    }

    this.onChange({ verifiedBy: verifiedBy });
  };

  renderDomainSection() {
    const { domain, verifiedBy } = this.props;
    if (['azure', 'oidc', 'okta'].includes(verifiedBy)) {
      return null;
    }

    return (
      <FormField className="domainInput" label="Domain">
        <TextInput
          onChange={this.onDomainInputChange}
          placeholder="canny.io, canny.com"
          value={domain || ''}
        />
      </FormField>
    );
  }

  renderInvites() {
    const { board } = this.props;
    if (!board) {
      return null;
    }

    if (!board.invites.length) {
      return null;
    }

    const invites = board.invites;
    const inviteItems = [];
    invites.forEach((invite) => {
      const { email, name } = invite;
      inviteItems.push(
        <div key={email} className="invite">
          {email}
          {name && ' (' + name + ')'}
          <Tappable onTap={this.onRevokeAccess.bind(this, email)}>
            <div className="revoke">revoke</div>
          </Tappable>
        </div>
      );
    });

    return <div className="invites">{inviteItems}</div>;
  }

  renderInviteSection() {
    const { board } = this.props;
    return (
      <div className={classnames('inviteSection', { hasInvites: board && board.invites.length })}>
        <FormField label="Email address">
          <AutoResizeTextarea
            onChange={this.onEmailInputChange}
            placeholder="jane@netflix.com, tim@youtube.com"
            ref={this.props.emailsRef}
          />
        </FormField>
        {this.renderInvites()}
      </div>
    );
  }

  renderPublicAccessSettings() {
    if (this.props.access !== BoardAccess.public) {
      return null;
    }

    const { company } = this.props;
    const { billingData } = company;

    const planID = billingData?.plan?.planID;
    const notPaying = !planID || isFree(planID);

    const indexed = !notPaying && this.props.indexed;
    return (
      <>
        <div className="optionDetail">
          <div className="optionNote">
            Index in search engines like Google
            {notPaying && (
              <Link to="/admin/settings/billing">
                <Pill pillStyle={DefaultPillStyles.canny} className="pill">
                  Paid Plan
                </Pill>
              </Link>
            )}
          </div>
          <div className="toggleContainer">
            <Toggle
              disabled={notPaying}
              onToggle={(indexed) => this.onChange({ indexed })}
              small
              value={indexed}
            />
          </div>
        </div>
        <div className="optionDetail">
          <div className="optionNote">Show on home page ({company.subdomain}.canny.io)</div>
          <div className="toggleContainer">
            <Toggle
              onToggle={(listed) => this.onChange({ unlisted: !listed })}
              small
              value={!this.props.unlisted}
            />
          </div>
        </div>
        <div className="note">
          Note: If your board already shows up in Google search results, it may take a few days to
          be removed. If this is an issue, let us know.
        </div>
      </>
    );
  }

  renderPrivateAccessSettings() {
    const {
      access,
      company: { features },
    } = this.props;

    if (access !== BoardAccess.private) {
      return null;
    }

    if (features?.privateBoards) {
      return null;
    }

    return (
      <AdminFeatureUpsell
        cta="Keep your feedback private"
        feature="privateBoards"
        planID={FreePlanID}
      />
    );
  }

  renderCustomAccessSettings() {
    const {
      access,
      company: { features },
    } = this.props;

    if (access !== BoardAccess.custom) {
      return null;
    }

    if (!features?.customAccess) {
      return (
        <AdminFeatureUpsell cta="Limit board access to specific users" feature="customAccess" />
      );
    }

    const userSegmentationEnabled = this.props.company.features.userSegmentation;
    const segmentOptions = [
      { name: segmentDefaultValue, render: 'Everyone (default)' },
      ...this.props.company.segments.map(({ name, urlName }) => ({ name: urlName, render: name })),
    ];

    return (
      <>
        <div className="optionDetail">
          <div className="toggleValueContainer">
            <div className="valueTitle">Users/customers</div>
            <div className="valueDescription">
              Grant access to anyone who has been identified via our{' '}
              <a href="https://developers.canny.io/install" rel="noopener" target="_blank">
                client&#8209;side&nbsp;SDK
              </a>{' '}
              or{' '}
              <a
                href="https://developers.canny.io/install/widget/sso"
                rel="noopener"
                target="_blank">
                single&nbsp;sign&#8209;on
              </a>
              .
            </div>
          </div>
          <div className="toggleContainer">
            <Toggle
              onToggle={(allowIdentified) => this.onChange({ allowIdentified })}
              small
              value={this.props.allowIdentified}
            />
          </div>
        </div>
        <div className="optionDetail">
          <div className="toggleValueContainer">
            <div className="valueTitle">Segment</div>
            <div className="valueDescription">
              Grant access to a specific segment of users/customers.{' '}
              <a
                href="https://help.canny.io/en/articles/3176557-user-segmentation"
                rel="noopener"
                target="_blank">
                Learn more about segmentation
              </a>
              .
            </div>
          </div>
          <div className="toggleContainer">
            <Dropdown
              defaultSelectedName={this.props.segment || segmentOptions[0].name}
              disabled={!userSegmentationEnabled}
              onChange={this.onSegmentChange}
              options={segmentOptions}
              truncate
            />
          </div>
        </div>
        <div className="optionDetail noMargin">
          <div className="toggleValueContainer">
            <div className="valueTitle">Collaborators</div>
            <div className="valueDescription">
              Allow anyone who has an @yourcompany.com email address to access the board (verified
              by email, Azure AD, Google Workspace, OpenID Connect or&nbsp;Okta).
            </div>
          </div>
          <div className="toggleContainer">
            <ControlledDropdown
              selectedName={this.props.verifiedBy}
              onChange={this.onVerifiedByChange}
              options={[
                {
                  name: 'email',
                  render: 'Email',
                },
                {
                  name: 'gsuite',
                  render: 'G Suite',
                },
                {
                  name: 'azure',
                  render: 'Azure AD',
                },
                {
                  name: 'oidc',
                  render: 'OpenID Connect',
                },
                {
                  name: 'okta',
                  render: 'Okta',
                },
              ]}
              placeholder={this.props.verifiedBy ? undefined : 'Select method'}
            />
          </div>
        </div>
        <div className="domainSection">{this.renderDomainSection()}</div>
        <div className="optionDetail noMargin">
          <div className="toggleValueContainer">
            <div className="valueTitle">Individual members</div>
            <div className="valueDescription">
              Invite specific people via email to access the board. You can invite up to 50 people
              at a time.
            </div>
          </div>
        </div>
        {this.renderInviteSection()}
      </>
    );
  }

  render() {
    const accessOptions = [
      {
        value: BoardAccess.private,
        label: 'Private',
        description: 'Only other Canny admins can see this board.',
      },
      {
        value: BoardAccess.public,
        label: 'Public',
        description: 'Any user can access this board.',
      },
      {
        value: BoardAccess.custom,
        label: 'Custom',
        description: 'Admins and any users that meet the criteria below will see this board.',
      },
    ];
    const selectedAccess = accessOptions.find((option) => option.value === this.props.access);

    return (
      <div className="adminBoardPrivacyForm">
        <div className="optionDetails">
          <div className="optionDetail">
            <div className="toggleValueContainer">
              <div className="valueTitle">Board access</div>
              <div className="valueDescription">
                Control who can access your board. Public boards are open to anyone, while private
                boards can only be seen internally. Boards with custom access can be configured
                as&nbsp;needed.
              </div>
            </div>
            <div className="switchContainer">
              <SingleSelect
                className="dropdown createPostFormV2CategorySelect"
                onChange={(option) => this.onChange({ access: option?.value })}
                value={selectedAccess}
                options={accessOptions}
              />
            </div>
          </div>
          <hr className="line" />
          <div className="accessHeader">
            <H5>{selectedAccess.label} Access</H5>
            {selectedAccess.value === BoardAccess.custom && (
              <Pill className={'accessPill'} style={DefaultPillStyles.purpleBackground}>
                Canny Growth
              </Pill>
            )}
          </div>
          <P className="accessDescription">{selectedAccess.description}</P>
          {this.renderPublicAccessSettings()}
          {this.renderPrivateAccessSettings()}
          {this.renderCustomAccessSettings()}
        </div>
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadBoard: (boardURLName) => dispatch(reloadBoard(boardURLName)),
  })),
  withContexts(
    {
      company: CompanyContext,
      openModal: OpenModalContext,
      viewer: ViewerContext,
    },
    {
      forwardRef: true,
    }
  )
)(AdminBoardPrivacyForm);
